<template>
  <div>
    <div class="vx-row mt-5 items-center">
      <div class="vx-col lg:w-1/5 w-full">
        <label> Auto Exam Name </label>
      </div>
      <div class="vx-col lg:w-4/5 w-full mt-3">
        <vs-input class="inputx w-full" />
      </div>
    </div>

    <div class="vx-row mt-5 items-center">
      <div class="vx-col lg:w-1/5 w-full">
        <label>
          Manual name
          <i class="">“optional”</i>
        </label>
      </div>
      <div class="vx-col lg:w-4/5 w-full mt-3">
        <vs-input class="inputx w-full" />
      </div>
    </div>

    <div class="vx-row mt-5 items-center">
      <div class="vx-col lg:w-4/5 w-full mt-3">
        <ul>
          <li v-for="(val, i) in Configuration" :key="i" class="mb-3">
            <vs-checkbox v-model="val.value" :vs-value="val.id">{{
              val.name
            }}</vs-checkbox>
          </li>
        </ul>
      </div>
    </div>

    <div class="vx-row mt-5 items-center">
      <div class="vx-col lg:w-1/5 w-full">
        <label> passing score % </label>
      </div>
      <div class="vx-col lg:w-2/5 w-full mt-3">
        <vs-input
          name="usual_absence_after"
          v-validate="'required'"
          class="w-full"
          value="70"
          icon-after="true"
          icon-pack="feather"
          icon="icon-percent"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      Configuration: [
        {
          name: 'Choose Display Language',
          value: true
        },
        {
          name: 'Randomize questions',
          value: true
        },
        {
          name: 'Lockdown functionality',
          value: true
        },
        {
          name: 'Show attempt counts',
          value: true
        },
        {
          name: 'Show result to Parent',
          value: true
        },
        {
          name: 'Show result to Student',
          value: true
        },
        {
          name: 'Present All questions of the exam to the student',
          value: true
        },
        {
          name: 'Allow review answers after finishing the exam',
          value: true
        },
        {
          name:
            'Allow answering questions in any order and jumping back and forth between questions.',
          value: true
        },
        {
          name: 'Set Instructions for the exam',
          value: true
        },

        {
          name: 'Publish automatically',
          value: true
        },
        {
          name: 'Set passing score',
          value: true
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>